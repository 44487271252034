<template>
  <v-container class="fill-height mt-md-3">
    <!-- <WidgetAlert /> -->
    <v-row justify="center">
      <v-col cols="12" class="mb-md-2 pt-md-2" align-self="start">
        <h2 class="header font-weight-bold my-2 white--text text-center">
          ESPAI CLIENT
        </h2>

        <v-spacer></v-spacer>

        <p class="subheading font-weight-regular mb-3 white--text text-center">
          Aquí pots veure les teves dades i gestionar les teves subscripcions a
          notificacions.
        </p>
      </v-col>
    </v-row>

    <v-row dense v-if="showError">
      <v-alert type="error" class="my-2 mx-auto">
        {{ alertMsg }}
      </v-alert>
    </v-row>

    <v-alert type="success" v-if="showSuccess" class="my-2 mx-auto">
      Les teves dades s'han actualitzat correctament!
    </v-alert>

    <v-row justify="center">
      <v-col cols="12" md="5">
        <v-card class="px-md-2">
          <v-card-title>
            <h4 class="title primary--text">Les meves dades</h4>
          </v-card-title>
          <v-card-text>
            <v-form
              ref="userDataForm"
              @submit.prevent="submitUserData"
              v-model="valid"
            >
              <v-row dense justify="center">
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="userDataForm.name"
                    :rules="[rules.required]"
                    autocomplete="name"
                    label="Nom"
                    name="input-name"
                    required
                    hint="El teu nom"
                    :readonly="editable === false"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="userDataForm.surnames"
                    :rules="[rules.required]"
                    autocomplete="cognoms"
                    label="Cognoms"
                    name="input-cognoms"
                    required
                    hint="Els teus cognoms"
                    :readonly="editable === false"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="userDataForm.phone"
                    :rules="[rules.required]"
                    autocomplete="telèfon"
                    label="Telèfon"
                    name="input-phone"
                    required
                    hint="El teu telèfon (sense espais)"
                    :readonly="editable === false"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="userDataForm.email"
                    :rules="[rules.required]"
                    autocomplete="email"
                    label="Email"
                    name="input-email"
                    required
                    hint="Ha de ser un email vàlid"
                    :readonly="editable === false"
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <!-- <v-row justify="center">
                <v-col cols="12" md="4">
                  <v-btn text color="orange darken-1" @click="reset()">
                    Cancelar
                  </v-btn>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn
                    x-large
                    block
                    :disabled="validResetForm === false"
                    color="success"
                    @click="submitResetPassword"
                  >
                    Enviar
                  </v-btn>
                </v-col>
              </v-row> -->
            </v-form>
          </v-card-text>
        </v-card>
        <!-- User Preferences -->
        <v-card dense class="px-md-1 my-2">
          <v-card-title>
            <h4 class="title primary--text mb-0">Preferències d'usuari</h4>
          </v-card-title>
          <v-card-text>
            <v-sheet dense class="pa-0">
              <!-- <v-switch
                v-model="userPrefs.scheduleSearcher"
                v-on:change="changeUserPrefs"
                inset
                dense
                label="Mostrar Cercador Horaris"
                color="success"
                class="my-0"
              ></v-switch> -->
              <v-switch
                v-model="userPrefs.scheduleTimeTable"
                v-on:change="changeUserPrefs"
                inset
                dense
                label="Llistat Horaris tipus Taula"
                color="success"
                class="my-0"
              ></v-switch>
              <!-- <v-switch
                v-model="userPrefs.pasbusForm"
                v-on:change="changeUserPrefs"
                inset
                dense
                label="Mostrar formulari reserva Pasbus"
                color="success"
                class="my-0"
              ></v-switch> -->
              <v-switch
                v-model="userPrefs.notificationsWidget"
                v-on:change="changeUserPrefs"
                inset
                dense
                label="Mostrar Notificacions per Subscripció"
                color="success"
                class="my-0"
              ></v-switch>
            </v-sheet>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="7">
        <v-card class="px-md-2 pb-1">
          <v-card-title class="mb-0">
            <h4 class="title primary--text">Les meves subscripcions</h4>
            <v-spacer></v-spacer>
            <v-btn @click="goToSubscriptions()" color="primary">
              Configurar Subscripcions
            </v-btn>
          </v-card-title>
          <v-list
            three-line
            dense
            disabled
            v-if="userSubscriptions.length >= 1"
            class="mt-0 pt-0 px-2"
          >
            <v-list-item-group v-if="userSubscriptions" active-class="">
              <v-list-item
                v-for="(item, index) in userSubscriptions"
                :key="index"
                class="list-padding-adjusted"
              >
                <v-list-item-content>
                  <v-row dense>
                    <v-col cols="auto">
                      <v-list-item-title>
                        <v-chip
                          label
                          flat
                          dark
                          color="grey darken-3"
                          class="mr-1"
                        >
                          Parada: {{ item.stop }}
                        </v-chip>
                      </v-list-item-title>
                    </v-col>
                    <v-col>
                      <v-list-item-subtitle>
                        <span class="body-2">
                          {{ item.address }}
                        </span>
                      </v-list-item-subtitle>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <v-alert v-else dense type="info" class="mb-3 mx-3">
            No estàs subscrit a notificacions de cap servei.
          </v-alert>

          <!-- <v-card-text>
            <v-btn @click="goToSubscriptions()" color="primary">
              Configurar Subscripcions
            </v-btn>
          </v-card-text> -->
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" align-self="end">
        <v-img
          :src="require('../assets/img/logo-autocars-del-penedes-petit.png')"
          class="mt-5"
          contain
          height="80"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import { mapActions } from "vuex";
// import WidgetAlert from "@/components/WidgetAlert";

export default {
  name: "user-profile",

  data: () => ({
    token: null,
    loadingLines: false,
    loadingStops: false,
    itemsLoading: false,
    userInfo: null,
    showError: false,
    showSuccess: false,
    alertMsg: "",
    valid: false,
    userDataForm: {
      name: "",
      surnames: "",
      phone: "",
      email: ""
    },
    editable: false,
    rules: {
      required: value => !!value || "Requerit"
      // min: v => (v && v.length >= 8) || "Min 8 caràcters"
    },
    // subscriptions: [],
    // selectedLine: null,
    // selectedLineId: null,
    // selectedStops: [],
    // selectedStopsIds: [],
    // showStopsList: false,
    // notifications: [],
    // User Preferences
    userPrefs: {
      scheduleSearcher: false, // Cercador Horaris
      scheduleTimeTable: false, // 0-Timeline, 1-Tabletime
      pasbusForm: false, // Formulari Pasbus
      notificationsWidget: false // Llistat notificacions
    }
  }),

  // components: {
  //   WidgetAlert
  // },

  created() {
    this.userInfo = this.$store.getters.getUserInfo;
    this.token = this.$store.state.token;
    // this.syncronizeSubscriptions();
  },

  beforeMount() {
    this.userDataForm.name = this.userInfo.name;
    this.userDataForm.surnames = this.userInfo.surnames;
    this.userDataForm.phone = this.userInfo.phone;
    this.userDataForm.email = this.userInfo.email;
    // this.subscriptions = this.$store.getters.getSubscriptions;
    // this.notifications = this.$store.getters.getNotifications;
    this.userPrefs = this.$store.getters.getUserPrefs;
  },

  computed: {
    showStopsByLine() {
      return this.subscriptionLineStops;
    },
    userSubscriptions() {
      return this.$store.getters.getSubscriptions;
    }
    // subscriptionsList() {
    //   return this.subscriptions;
    // }
  },

  methods: {
    // ...mapActions([]),
    validateUserForm() {
      this.$refs.userDataForm.validate();
    },

    reset() {
      this.$refs.userDataForm.reset();
      this.$router.push({ path: "/" }).catch(() => {});
    },

    errorAlert() {
      this.showError = true;
      var v = this;
      setTimeout(function() {
        v.showError = false;
      }, 4000);
      this.loadingLines = false;
      this.loadingStops = false;
    },

    successAlert() {
      this.showSuccess = true;
      var v = this;
      setTimeout(function() {
        v.showSuccess = false;
      }, 4000);
      this.loadingLines = false;
      this.loadingStops = false;
    },

    // async syncronizeSubscriptions() {
    //   const user = {
    //     token: this.token,
    //     user_id: this.userInfo.userId
    //   };
    //   await this.$store
    //     .dispatch("syncronizeSubscriptions", user)
    //     .then(response => {
    //       const serverSubscriptions = response.data;
    //       let dataFiltered = serverSubscriptions.filter(e => e.id !== null);
    //       if (
    //         this.$store.getters.getSubscriptions !== serverSubscriptions &&
    //         serverSubscriptions.length > 0
    //       ) {
    //         console.log(
    //           "Les subscripcions locals i les remotes no son iguals!"
    //         );
    //         this.$store.commit("addSubscriptions", dataFiltered);
    //       } else if (serverSubscriptions.length === 0) {
    //         this.$store.commit("addSubscriptions", []);
    //       }
    //     })
    //     .catch(error => {
    //       console.log(error);
    //       this.alertMsg =
    //         "Oops! Hi ha hagut un problema de comunicació amb el servei.";
    //       this.errorAlert();
    //     });
    // },

    goToSubscriptions() {
      this.$router.push({ path: "/subscriptions" }).catch(() => {});
    },

    changeUserPrefs() {
      this.$store.dispatch("setUserPrefs", this.userPrefs);
    }
  }
};
</script>

<style scoped>
.list-padding-adjusted {
  min-height: 60px !important;
  border: 1px solid rgba(207, 216, 220, 1);
  border-radius: 5px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
